import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { monitoringPresetKeys } from "src/data/profileSettings/queries/monitoringPresetQueryCache"
import { getProfilesNoiseResponse } from "src/data/profileSettings/queries/noiseMonitoringPresetsMockData"
import { sleep } from "src/utils/genericUtil"

type TPreset = TFetchNoiseMonitoringPresetsResponse["profiles"][number]

export type TFetchNoiseMonitoringPresetsResponse =
  typeof getProfilesNoiseResponse
export function useFetchNoiseMonitoringPresets() {
  async function fetchNoiseMonitoringPresets() {
    return await noiseMonitoringPresetsMockDatabase.list()
  }

  return useQuery({
    queryKey: monitoringPresetKeys.noisePresets(),
    queryFn: fetchNoiseMonitoringPresets,
  })
}

export function useFetchIndoorClimateMonitoringPresets() {
  async function fetchIndoorClimateMonitoringPresets() {
    const response = {
      profiles: icmProfiles,
      paging: {
        total: 2,
        limit: 20,
        offset: 0,
      },
    }
    return Promise.resolve(response)
  }

  return useQuery({
    queryKey: monitoringPresetKeys.icmPresets(),
    queryFn: fetchIndoorClimateMonitoringPresets,
  })
}

export function usePostNoiseMonitoringPresets() {
  const queryClient = useQueryClient()
  async function patchNoiseMonitoringPresets(data: TPreset) {
    await noiseMonitoringPresetsMockDatabase.add(data)
  }

  return useMutation({
    mutationFn: patchNoiseMonitoringPresets,
    onSuccess: () => {
      return queryClient.invalidateQueries(monitoringPresetKeys.noisePresets())
    },
  })
}

export function useDeleteNoiseMonitoringPresets() {
  const queryClient = useQueryClient()

  async function deleteNoiseMonitoringPresets(id: string) {
    await noiseMonitoringPresetsMockDatabase.del(id)
  }

  return useMutation({
    mutationFn: deleteNoiseMonitoringPresets,
    onSuccess: () => {
      return queryClient.invalidateQueries(monitoringPresetKeys.noisePresets())
    },
  })
}

//#region
// Using static data for now. Will swap this out with real data later when the
// backend is ready
const icmProfiles = [
  {
    id: "1",
    name: "Standard indoor climate Profile",
  },
  {
    id: "2",
    name: "Strict indoor climate Profile",
  },
]

//#endregion

//#region Mock database
const noiseMonitoringPresetsMockDatabase = {
  data: getProfilesNoiseResponse,
  async list() {
    await sleep(200)
    return this.data
  },
  async add(data: TPreset) {
    await sleep(500)
    this.data = {
      paging: { total: this.data.profiles.length + 1, limit: 20, offset: 0 },
      profiles: [
        ...this.data.profiles,
        {
          ...data,
          id: Math.random().toString(),
        },
      ],
    }
  },
  async del(id: string) {
    await sleep(500)
    this.data = {
      paging: { total: this.data.profiles.length - 1, limit: 20, offset: 0 },
      profiles: this.data.profiles.filter((p) => p.id !== id),
    }
  },
}
//#endregion Mock database
