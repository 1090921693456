import styled from "styled-components"

import { DeviceMonitoringStatus } from "src/components/Devices/DeviceMonitoringStatus"
import { IndoorClimateStatus } from "src/components/Devices/IndoorClimateStatus/IndoorClimateStatus"
import { OnlineStatus } from "src/components/Homes/DeviceDetails/OnlineStatus"
import { TDevice } from "src/data/devices/types/deviceTypes"
import {
  IStatefulHome,
  IStatefulHomeWithDevices,
} from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TPartialLocation } from "src/router/routeTypes"
import { mColors } from "src/ui/colors"
import ImportantIcon from "src/ui/icons/important-filled.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MLinkStyle, MLinkStyleProps } from "src/ui/Link/MLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DeviceTableMini({
  title,
  homesWithDevices,
  linkTo,
  showIndoorClimate = false,
}: {
  title: React.ReactNode
  homesWithDevices: IStatefulHomeWithDevices[]
  linkTo: TPartialLocation | string | null
  showIndoorClimate?: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <MText variant="bodyS" color="secondary" marginBottom={spacing.S}>
        {title}
      </MText>

      {homesWithDevices.map((home) =>
        showIndoorClimate ? (
          <IndoorClimateRow
            key={home.home_id}
            home={home}
            devices={home.devices}
          />
        ) : (
          <Row key={home.home_id} home={home} devices={home.devices} />
        )
      )}

      {linkTo && (
        <Footer>
          <InternalLink to={linkTo}>
            {t(langKeys.see_all_homes_text)}
          </InternalLink>
        </Footer>
      )}
    </Box>
  )
}

function Row({ home, devices }: { home: IStatefulHome; devices: TDevice[] }) {
  const link = Routes.Home.location(home.home_id)

  const alertColor =
    home.homeAlertState === "emergency"
      ? mColors.systemError
      : mColors.systemWarning

  return (
    <InternalLink to={link} underline="never">
      <RowBox>
        <IconBox>
          <ImportantIcon fill={alertColor} width={20} height={20} />
        </IconBox>

        <div>
          <MText variant="subtitle" marginBottom={spacing.XS2}>
            <LinkStyle>{home.name}</LinkStyle>
          </MText>

          {devices.map((device) => (
            <DeviceDetails key={device.device_id} device={device} />
          ))}
        </div>
      </RowBox>
    </InternalLink>
  )
}

function IndoorClimateRow({
  home,
  devices,
}: {
  home: IStatefulHome
  devices: TDevice[]
}) {
  const link = Routes.Home.location(home.home_id)

  return (
    <InternalLinkBox to={link} underline="never">
      <MText variant="subtitle" marginBottom={spacing.XS2}>
        <LinkStyle>{home.name}</LinkStyle>
      </MText>

      {devices.map((device) => (
        <DetailsBox key={device.device_id}>
          <MText variant="body">{device.description}</MText>

          <IndoorClimateStatus
            device={device}
            indoorClimateMonitoring={home.indoor_climate_monitoring}
          />
        </DetailsBox>
      ))}
    </InternalLinkBox>
  )
}

function DeviceDetails({ device }: { device: TDevice }) {
  return (
    <DetailsBox>
      <MText variant="body">{device.description}</MText>

      <StatusBox>
        <DeviceMonitoringStatus device={device} />

        <OnlineStatus online={!device.offline} />
      </StatusBox>
    </DetailsBox>
  )
}

const Box = styled.div`
  width: max-content;
  padding: ${spacing.M};

  > *:not(:first-child) {
    margin-bottom: ${spacing.M};
  }

  > :last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
`

const RowBox = styled.div`
  display: grid;
  gap: ${spacing.M};
  grid-template-columns: auto 1fr;
`

const LinkStyle = styled.div<MLinkStyleProps>`
  ${MLinkStyle};
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const IconBox = styled.div`
  align-self: flex-start;
`

const DetailsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
`

const StatusBox = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: ${spacing.S};
`
const InternalLinkBox = styled(InternalLink)`
  display: grid;
  gap: ${spacing.XS};
`
