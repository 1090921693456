import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import HomeGroupsEmptyState from "./images/locations-empty-state.svg"

export function NoHomeGroups() {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <ImageBox>
        <HomeGroupsEmptyState />
      </ImageBox>
      <ContentWrapper>
        <MText variant="heading2" textAlign="center" marginBottom={spacing.XS}>
          {t(langKeys.locations_empty_state_title)}
        </MText>
        <MText variant="bodyS" textAlign="center" marginBottom={spacing.L}>
          {t(langKeys.locations_empty_state_description)}
        </MText>
        <CenterWrapper>
          <MButton appHref={Routes.CreateHomeGroup.location()}>
            {t(langKeys.organizations_home_group_create_button_label)}
          </MButton>
        </CenterWrapper>
      </ContentWrapper>
    </div>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  margin-top: -2rem;
  margin-inline: auto;
  max-width: 600px;
  text-wrap: pretty;
`

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ImageBox = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;

  > svg {
    width: 100%;
    height: 100%;
  }
`
