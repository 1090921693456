import { NoiseMonitoringPresetTable } from "src/components/SettingsProfiles/MonitoringPresets/NoiseMonitoringPresetTable"
import { useFetchNoiseMonitoringPresets } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"

type NoiseMonitoringPresetsProps = { hidden?: boolean }

export function NoiseMonitoringPresets({
  hidden,
}: NoiseMonitoringPresetsProps) {
  const { t, langKeys, tPlaceholder } = useTranslate()
  const fetchNoiseMonitoringPresets = useFetchNoiseMonitoringPresets()

  if (hidden) {
    return null
  }

  return (
    <BreadcrumbView
      breadcrumbs={[
        { to: Routes.Settings.location(), label: t(langKeys.settings) },
        {
          to: Routes.SettingsPresets.location(),
          label: tPlaceholder("Monitoring presets"),
        },
        { to: { pathname: "#" }, label: tPlaceholder("Noise monitoring") },
      ]}
      title={tPlaceholder("Monitoring presets")}
      description={tPlaceholder(
        "Tweak your thresholds, quiet hours and noise alerts."
      )}
      actionBar={
        <MButton onClick={() => alert("Not implemented")} variant="secondary">
          {tPlaceholder("Create a new preset")}
        </MButton>
      }
    >
      <NoiseMonitoringPresetTable
        presets={fetchNoiseMonitoringPresets.data?.profiles}
      />
    </BreadcrumbView>
  )
}
