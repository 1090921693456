/* eslint-disable @typescript-eslint/no-unused-vars */

// GET /organizations/{organization_id}/profiles/noise
export const getProfilesNoiseResponse = {
  profiles: [
    {
      id: "np-001",
      organization_id: "org-123",
      created_at: "2023-04-01T10:00:00Z",
      last_modified: "2023-04-01T10:00:00Z",
      name: "Standard Profile",
      number_of_homes: 50,
      disturbance_monitoring: {
        first_notice_sms_enabled: true,
        second_notice_sms_enabled: true,
        third_notice_sms_enabled: false,
        first_notice_flash_and_sound: true,
        second_notice_flash_and_sound: true,
        third_notice_flash_and_sound: false,
        first_notice_autocall_enabled: false,
        second_notice_autocall_enabled: true,
        third_notice_autocall_enabled: false,
        call_assist: true,
      },
      indoor_noise_threshold: {
        threshold: 70,
        duration_seconds: 300,
        quiet_hours_enabled: true,
        quiet_hours: {
          start_time_of_day: "22:00",
          end_time_of_day: "07:00",
          threshold: 65,
          duration_seconds: 300,
        },
      },
      outdoor_noise_threshold: {
        threshold: 75,
        duration_seconds: 300,
        quiet_hours_enabled: false,
      },
    },
    {
      id: "np-002",
      organization_id: "org-123",
      created_at: "2023-04-02T14:30:00Z",
      last_modified: "2023-04-02T14:30:00Z",
      name: "Strict Quiet Hours",
      number_of_homes: 30,
      disturbance_monitoring: {
        first_notice_sms_enabled: true,
        second_notice_sms_enabled: true,
        third_notice_sms_enabled: true,
        first_notice_flash_and_sound: true,
        second_notice_flash_and_sound: true,
        third_notice_flash_and_sound: true,
        first_notice_autocall_enabled: false,
        second_notice_autocall_enabled: false,
        third_notice_autocall_enabled: true,
        call_assist: false,
      },
      indoor_noise_threshold: {
        threshold: 68,
        duration_seconds: 360,
        quiet_hours_enabled: true,
        quiet_hours: {
          start_time_of_day: "21:00",
          end_time_of_day: "08:00",
          threshold: 60,
          duration_seconds: 300,
        },
      },
      outdoor_noise_threshold: {
        threshold: 72,
        duration_seconds: 360,
        quiet_hours_enabled: true,
        quiet_hours: {
          start_time_of_day: "21:00",
          end_time_of_day: "08:00",
          threshold: 65,
          duration_seconds: 300,
        },
      },
    },
  ],
  paging: {
    total: 2,
    limit: 20,
    offset: 0,
  },
}

// POST /organizations/{organization_id}/profiles/noise
// Request Body:
const createProfileNoiseRequest = {
  name: "New Custom Profile",
  disturbance_monitoring: {
    first_notice_sms_enabled: true,
    second_notice_sms_enabled: true,
    third_notice_sms_enabled: false,
    first_notice_flash_and_sound: false,
    second_notice_flash_and_sound: true,
    third_notice_flash_and_sound: false,
    first_notice_autocall_enabled: false,
    second_notice_autocall_enabled: false,
    third_notice_autocall_enabled: false,
    call_assist: true,
  },
  indoor_noise_threshold: {
    threshold: 72,
    duration_seconds: 330,
    quiet_hours_enabled: true,
    quiet_hours: {
      start_time_of_day: "23:00",
      end_time_of_day: "07:00",
      threshold: 65,
      duration_seconds: 300,
    },
  },
  outdoor_noise_threshold: {
    threshold: 77,
    duration_seconds: 330,
    quiet_hours_enabled: false,
  },
}

// Response:
const createProfileNoiseResponse = {
  profiles: [
    {
      id: "np-003",
      organization_id: "org-123",
      created_at: "2023-04-10T09:30:00Z",
      last_modified: "2023-04-10T09:30:00Z",
      name: "New Custom Profile",
      number_of_homes: 0,
      disturbance_monitoring: {
        first_notice_sms_enabled: true,
        second_notice_sms_enabled: true,
        third_notice_sms_enabled: false,
        first_notice_flash_and_sound: false,
        second_notice_flash_and_sound: true,
        third_notice_flash_and_sound: false,
        first_notice_autocall_enabled: false,
        second_notice_autocall_enabled: false,
        third_notice_autocall_enabled: false,
        call_assist: true,
      },
      indoor_noise_threshold: {
        threshold: 72,
        duration_seconds: 330,
        quiet_hours_enabled: true,
        quiet_hours: {
          start_time_of_day: "23:00",
          end_time_of_day: "07:00",
          threshold: 65,
          duration_seconds: 300,
        },
      },
      outdoor_noise_threshold: {
        threshold: 77,
        duration_seconds: 330,
        quiet_hours_enabled: false,
      },
    },
  ],
  paging: {
    total: 1,
    limit: 20,
    offset: 0,
  },
}

// GET /organizations/{organization_id}/profiles/noise/np-001
const getProfileNoiseResponse = {
  id: "np-001",
  organization_id: "org-123",
  created_at: "2023-04-01T10:00:00Z",
  last_modified: "2023-04-01T10:00:00Z",
  name: "Standard Profile",
  number_of_homes: 50,
  disturbance_monitoring: {
    first_notice_sms_enabled: true,
    second_notice_sms_enabled: true,
    third_notice_sms_enabled: false,
    first_notice_flash_and_sound: true,
    second_notice_flash_and_sound: true,
    third_notice_flash_and_sound: false,
    first_notice_autocall_enabled: false,
    second_notice_autocall_enabled: true,
    third_notice_autocall_enabled: false,
    call_assist: true,
  },
  indoor_noise_threshold: {
    threshold: 70,
    duration_seconds: 300,
    quiet_hours_enabled: true,
    quiet_hours: {
      start_time_of_day: "22:00",
      end_time_of_day: "07:00",
      threshold: 65,
      duration_seconds: 300,
    },
  },
  outdoor_noise_threshold: {
    threshold: 75,
    duration_seconds: 300,
    quiet_hours_enabled: false,
  },
}

// PATCH /organizations/{organization_id}/profiles/noise/np-001
// Request Body:
const updateProfileNoiseRequest = {
  name: "Updated Standard Profile",
  disturbance_monitoring: {
    second_notice_sms_enabled: false,
    third_notice_flash_and_sound: true,
  },
  indoor_noise_threshold: {
    threshold: 68,
    duration_seconds: 320,
  },
}

// Response:
const updateProfileNoiseResponse = {
  id: "np-001",
  organization_id: "org-123",
  created_at: "2023-04-01T10:00:00Z",
  last_modified: "2023-04-10T11:15:00Z",
  name: "Updated Standard Profile",
  number_of_homes: 50,
  disturbance_monitoring: {
    first_notice_sms_enabled: true,
    second_notice_sms_enabled: false,
    third_notice_sms_enabled: false,
    first_notice_flash_and_sound: true,
    second_notice_flash_and_sound: true,
    third_notice_flash_and_sound: true,
    first_notice_autocall_enabled: false,
    second_notice_autocall_enabled: true,
    third_notice_autocall_enabled: false,
    call_assist: true,
  },
  indoor_noise_threshold: {
    threshold: 68,
    duration_seconds: 320,
    quiet_hours_enabled: true,
    quiet_hours: {
      start_time_of_day: "22:00",
      end_time_of_day: "07:00",
      threshold: 65,
      duration_seconds: 300,
    },
  },
  outdoor_noise_threshold: {
    threshold: 75,
    duration_seconds: 300,
    quiet_hours_enabled: false,
  },
}

// DELETE /organizations/{organization_id}/profiles/noise/np-002
// Successful response:
const deleteProfileNoiseResponse = {
  message: "Noise profile successfully deleted",
}
