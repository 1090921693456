import { useEffect, useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import HeartIcon from "src/components/VirtualDeviceControl/heart-icon.svg"
import { VirtualA1DeviceControls } from "src/components/VirtualDeviceControl/VirtualA1DeviceControls"
import { isEurekaDevice } from "src/data/devices/logic/deviceLogic"
import { TVirtualDevice } from "src/data/devices/types/deviceTypes"
import { useFetchVirtualDevices } from "src/data/homes/queries/virtualDeviceQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHome,
  useFetchHomes,
} from "src/data/organizations/queries/homeQueries"
import { DropdownListButton } from "src/ui/Button/DropdownListButton"
import { brandText } from "src/ui/colors"
import CloseIcon from "src/ui/icons/close.svg"
import ImportantIcon from "src/ui/icons/important-outlined.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { isString } from "src/utils/genericUtil"
import { formatDateAsTime } from "src/utils/l10n"

import { VirtualMDeviceControls } from "./VirtualMDeviceControls"

export function VirtualDeviceControlCard({
  onClose: setClose,
  isOpen,
}: {
  onClose: () => void
  isOpen: boolean
}) {
  const { org } = useOrganization()
  const [simulatedDeviceId, setSimulatedDeviceId] = useState<string>()

  const fetchVirtualDevices = useFetchVirtualDevices({
    orgId: org.id,
    options: {
      onSuccess: (devices) => {
        if (devices.length > 0 && simulatedDeviceId === undefined) {
          setSimulatedDeviceId(devices[0].device_id)
        }
      },
    },
  })

  const virtualDevices = fetchVirtualDevices.data || []

  const simulatedDevice =
    virtualDevices.find((d) => d.device_id === simulatedDeviceId) ??
    virtualDevices?.[0]

  const fetchHome = useFetchHome({
    orgId: org.id,
    homeId: simulatedDevice?.home ?? "",
  })
  const simulatedDeviceHome = fetchHome.data

  const homeIds = virtualDevices.map((d) => d.home)?.filter(isString) ?? []

  const fetchHomes = useFetchHomes({ orgId: org.id, filters: { ids: homeIds } })

  if (fetchVirtualDevices.error) {
    return null
  }

  if (!virtualDevices.length) {
    return (
      <Box $isOpen={isOpen}>
        <div style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <CloseButton onClick={setClose} size="small">
            <CloseIcon width={24} color={brandText} />
          </CloseButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ImportantIcon width={64} />
          <p>
            No devices added yet. Open a home and add a virtual sensor from
            there
          </p>
        </div>
      </Box>
    )
  }

  function VirtualDeviceControls({ home }: { home: THome | undefined }) {
    if (!home) {
      return <div>No home</div>
    }

    if (isEurekaDevice(simulatedDevice)) {
      return <VirtualA1DeviceControls simulatedDevice={simulatedDevice} />
    }
    return (
      <VirtualMDeviceControls
        key={simulatedDeviceId}
        orgId={org.id}
        home={home}
        simulatedDevice={simulatedDevice}
      />
    )
  }

  return (
    <Box $isOpen={isOpen}>
      <div style={{ display: "inline-flex", justifyContent: "space-between" }}>
        <DropdownListButton
          displayValue={"Select device"}
          options={virtualDevices.map((d) => ({
            name: `${
              fetchHomes.data?.homes?.find((h) => h.home_id === d.home)?.name ??
              d.home
            }, ${d.description}`,
            value: d.device_id,
            onClick: () => {
              setSimulatedDeviceId(d.device_id)
            },
          }))}
        ></DropdownListButton>
        <CloseButton onClick={setClose} size="small">
          <CloseIcon width="24px" />
        </CloseButton>
      </div>

      <ControlCardHeader
        homeName={simulatedDeviceHome?.name}
        simulatedDevice={simulatedDevice}
        onTimeout={() => fetchVirtualDevices.refetch()}
      />

      <VirtualDeviceControls home={simulatedDeviceHome} />
    </Box>
  )
}

const CloseButton = styled(IconButton)``

const Box = styled.div<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? "" : "translate(0, 40rem) ")};
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: ${spacing.M};
  right: ${spacing.M};
  min-height: 200px;
  width: 600px;
  border-radius: ${spacing.XS};
  border: 1px solid #e6e6e6;
  padding: ${spacing.L};
  background-color: white;
  box-shadow: 0 0 30px #ccc;
`

function ControlCardHeader({
  homeName,
  simulatedDevice,
  onTimeout,
}: {
  homeName: string | undefined
  simulatedDevice: TVirtualDevice
  onTimeout: () => void
}) {
  const [timeleft, setTimeleft] = useState(0)
  useEffect(() => {
    if (!simulatedDevice?.next_heartbeat_at) {
      return
    }
    // XXX: This counter implementation seems kinda broken and should probably
    // be fixed at some point.
    return startCountdown(
      simulatedDevice.next_heartbeat_at,
      setTimeleft,
      onTimeout
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulatedDevice?.next_heartbeat_at])

  return (
    <HeaderBox>
      <div>
        <MText variant="heading2">{homeName}</MText>
        <div>{simulatedDevice.description}</div>
      </div>

      <NextHeartbeatWrapper>
        <div style={{ gridColumn: 1 }}>
          <HeartIcon style={{ width: "1.5rem" }} />
        </div>

        <div style={{ gridColumn: 2 }}>
          Next heartbeat:{" "}
          {simulatedDevice.next_heartbeat_at
            ? formatDateAsTime({
                date: simulatedDevice.next_heartbeat_at,
                clockType: "24",
              })
            : "N/A"}
        </div>
        <div style={{ gridColumn: 2 }}>({secondsToTimeFormat(timeleft)})</div>
      </NextHeartbeatWrapper>
    </HeaderBox>
  )
}

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
`
const NextHeartbeatWrapper = styled.div`
  font-size: ${spacing.M};
  margin-left: ${spacing.XS};
  margin-bottom: ${spacing.M};
  display: grid;
  grid-template-columns: auto auto;
`

// Format seconds into a human readable string, MM:SS
function secondsToTimeFormat(timeLeft: number): string {
  const minutes = Math.floor(timeLeft / 60)
  const seconds = Math.floor(timeLeft - minutes * 60)
  return `${minutes < 10 ? "0" + minutes : minutes}:${
    seconds < 10 ? "0" + seconds : seconds
  }`
}

function startCountdown(
  end: string,
  onCountdown: (countdown: number) => void,
  onCountdownEnd: () => void
): () => void {
  const endDate = new Date(end)
  const interval = window.setInterval(() => {
    const secondsLeft = Math.floor(
      (endDate.getTime() - new Date().getTime()) / 1000
    )
    if (secondsLeft >= 0) {
      onCountdown(secondsLeft)
    } else {
      onCountdownEnd()
    }
  }, 1000)
  return () => clearInterval(interval)
}
