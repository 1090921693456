import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { Switch, Tooltip } from "@material-ui/core"
import { useSnackbar } from "notistack"

import { MonitoringPopup } from "src/components/Homes/HomeDetails/MonitoringPopup"
import { DynamicSecurityAlarmIcon } from "src/components/Homes/HomeDetails/SecurityAlarm/DynamicSecurityAlarmIcon"
import { getCountdown } from "src/data/homeActions/logic/countdown"
import {
  getSecurityAlarmLabel,
  getUIAlarmState,
} from "src/data/homeActions/logic/securityAlarm"
import { AlarmMode, AlarmStatus } from "src/data/homes/types/alarmTypes"
import { Violations } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHomeSecurityAlarm } from "src/data/organizations/queries/homeQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { Divider } from "src/ui/Divider/Divider"
import HomeIcon from "src/ui/icons/home.svg"
import SecurityAlarmOffOutlineIcon from "src/ui/icons/security-alarm-off-outline.svg"
import SecurityAlarmOffOutlineActiveIcon from "src/ui/icons/security-alarm-off-outline-active.svg"
import SecurityAlarmOnOutlineIcon from "src/ui/icons/security-alarm-on-outline.svg"
import SecurityAlarmOnOutlineActiveIcon from "src/ui/icons/security-alarm-on-outline-active.svg"
import SilentAlarmOnIcon from "src/ui/icons/silent-alarm-on.svg"
import { MonitoringButton } from "src/ui/MonitoringButton/MonitoringButton"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

export function SecurityAlarmButtonLabel({
  now,
  deadline,
  alarmStatus,
}: {
  now: number
  deadline: number | null
  alarmStatus: AlarmStatus | undefined
}): JSX.Element {
  const { t } = useTranslate()
  return (
    <div
      // This fixed-width hack is necessary to prevent the column widths from
      // jumping when the countdown changes when the alarm is rendered in a table:
      style={{
        textAlign: "center",
        width: `${5 + getCountdown(now, deadline).length}ch`,
      }}
    >
      {getSecurityAlarmLabel({ t, alarmStatus, deadline, now })}
    </div>
  )
}

// TODO WEB-357: Split into multiple components
export function SecurityAlarmButton({
  homeId,
  alarmStatus,
  silentAlarm,
  gracePeriodSecs,
  alarmMode: _alarmMode,
  gracePeriodExpiresAt,
  violations = [],
  showAlarmArmDelayHint = false,
  hideStatus,
  disabled = false,
  toggleAllowed,
  graphicalButton = false,
  ...props
}: {
  homeId: string
  silentAlarm: boolean | undefined
  alarmStatus?: AlarmStatus
  hideStatus?: boolean
  alarmMode: AlarmMode | undefined
  gracePeriodSecs: number | undefined
  gracePeriodExpiresAt: string | undefined
  hideLabel?: boolean
  disabled?: boolean
  showAlarmArmDelayHint: boolean
  violations?: Violations[]
  graphicalButton?: boolean
  row?: boolean
  toggleAllowed: boolean
  style?: React.ReactNode
}) {
  const { org } = useOrganization()
  const { t } = useTranslate()
  const patchHomeSecurityAlarm = usePatchHomeSecurityAlarm()
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const isOn = alarmStatus === "on"
  const isTogglingOn = alarmStatus === "on_grace_period"
  const isOff = alarmStatus === "off"
  const isManuallyOn = isOn || isTogglingOn
  const open = Boolean(anchorEl)

  const deadline = gracePeriodExpiresAt
    ? new Date(gracePeriodExpiresAt).getTime()
    : null
  const [now, setNow] = useState(Date.now())
  const uiAlarmState = alarmStatus && getUIAlarmState(alarmStatus, deadline)

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const mTooltip = toggleAllowed ? "" : t(langKeys.not_enough_permissions)

  useEffect(() => {
    if (graphicalButton && uiAlarmState !== "on_grace_period") {
      return
    }
    setNow(Date.now())
    const interval = setInterval(() => {
      setNow(Date.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [uiAlarmState, graphicalButton])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setError(null)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const handleSetAlarmStatus = async (alarmStatus: any) => {
    handleClose()

    setIsUpdating(true)
    const onError = (error: Error) => {
      ErrorService.captureException(error)

      enqueueSnackbar(t(langKeys.failed_contact_support), {
        variant: "error",
      })
    }

    const orgId = org?.id
    if (!orgId) {
      throw Error("No active team")
    }

    patchHomeSecurityAlarm.mutate(
      { orgId, homeId, data: { alarm_status: alarmStatus } },
      { onError }
    )

    mounted.current && setIsUpdating(false)
  }

  function handleSilentAlarmToggle() {
    return patchHomeSecurityAlarm.mutate({
      orgId: org.id,
      homeId,
      data: { silent_alarm: !silentAlarm },
    })
  }

  return (
    <>
      <Tooltip title={mTooltip} placement="left">
        <div>
          {!!graphicalButton ? (
            <MonitoringButton
              label={
                <SecurityAlarmButtonLabel
                  now={now}
                  deadline={deadline}
                  alarmStatus={uiAlarmState}
                />
              }
              labelType={((isOn || isTogglingOn) && "good") || "default"}
              hideStatus={hideStatus}
              onClick={handleClick}
              updating={isUpdating}
              disabled={disabled || !toggleAllowed}
              icon={
                <DynamicSecurityAlarmIcon
                  now={now}
                  deadline={deadline}
                  gracePeriodSecs={gracePeriodSecs}
                  alarmStatus={uiAlarmState}
                  iconProps={{ width: 30, height: 30 }}
                />
              }
              {...props}
            >
              <>
                {t(langKeys.home_security_alarm_title)}
                <Modes>{silentAlarm && <SilentAlarmOnIcon width={20} />}</Modes>
              </>
            </MonitoringButton>
          ) : (
            <MButton
              variant="subtle"
              size="small"
              onClick={handleClick}
              loading={isUpdating}
              disabled={disabled || !toggleAllowed}
            >
              {t(langKeys.manage)}
            </MButton>
          )}
        </div>
      </Tooltip>
      <MonitoringPopup
        open={open}
        error={error}
        anchorEl={anchorEl}
        onClose={handleClose}
        title={t(langKeys.home_security_alarm_title)}
        description={t(langKeys.home_security_alarm_short_description)}
        warning={t(langKeys.subscription_violations_alarm_warning)}
        info={
          showAlarmArmDelayHint ? (
            <>
              <Divider $margin={spacing.M} />
              {t(langKeys.instantly_turn_on_alarm_off_hint)}
            </>
          ) : undefined
        }
        violations={violations}
        buttons={[
          {
            id: "securityAlarmOff",
            label: t(langKeys.off),
            icon: isOff ? (
              <AlarmButtonBox>
                <SecurityAlarmOffOutlineActiveIcon />
              </AlarmButtonBox>
            ) : (
              <AlarmButtonBox>
                <SecurityAlarmOffOutlineIcon />
              </AlarmButtonBox>
            ),
            onClick: () => handleSetAlarmStatus("off"),
            disablePointerEvents: isOff,
          },
          {
            id: "securityAlarmOn",
            label: t(langKeys.on),
            icon: isManuallyOn ? (
              <AlarmButtonBox>
                <SecurityAlarmOnOutlineActiveIcon />
              </AlarmButtonBox>
            ) : (
              <AlarmButtonBox>
                <SecurityAlarmOnOutlineIcon />
              </AlarmButtonBox>
            ),
            onClick: () => handleSetAlarmStatus("on"),
            disableOnWarning: true,
            disablePointerEvents: isManuallyOn,
          },
        ]}
        subtitle={
          <>
            <StyledHomeIcon width="24px" height="24px" />
            {t(langKeys.home_indoor_label)}
          </>
        }
      >
        <Tooltip
          title={t(langKeys.home_settings_silent_alarm_description)}
          placement="top"
          arrow
          key="silentAlarm"
        >
          <StyledSilentAlarmBox>
            {t(langKeys.silent_alarm_title)}
            <Switch
              checked={silentAlarm}
              onChange={handleSilentAlarmToggle}
              disabled={patchHomeSecurityAlarm.isLoading}
            />
          </StyledSilentAlarmBox>
        </Tooltip>
      </MonitoringPopup>
    </>
  )
}

const Modes = styled.div`
  position: absolute;
  top: ${spacing.S};
  right: ${spacing.S};
  display: flex;
  flex-direction: column;
  width: 25px;

  > * {
    margin-bottom: ${spacing.XS};
  }
`

const AlarmButtonBox = styled.div`
  max-width: 56px;
  margin: 0 auto;
`

const StyledHomeIcon = styled(HomeIcon)`
  margin-right: ${spacing.XS};
`

const StyledSilentAlarmBox = styled.div`
  display: flex;
  margin-top: ${spacing.S};
  gap: ${spacing.L};
  align-items: center;
  justify-content: center;
`
