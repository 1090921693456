import { ReactElement } from "react"
import { Redirect, Route, RouteProps, Switch } from "react-router-dom"

import DeepRedirect from "src/components/DeepRedirect/DeepRedirect"
import { EmptyState } from "src/components/EmptyState"
import { Homes } from "src/components/Homes/Homes"
import { ParadiseRoute } from "src/components/ParadiseRoute/ParadiseRoute"
import {
  PrivateRoute,
  UnauthenticatedRoute,
} from "src/components/PrivateRoute/PrivateRoute"
import { NoiseMonitoringPresets } from "src/components/SettingsProfiles/MonitoringPresets/NoiseMonitoringPresets"
import {
  HREF_MINUT_BILLING,
  HREF_MINUT_HELP_CROWD_DETECT,
  HREF_MINUT_HELP_SHORTCODES,
} from "src/constants/hrefs"
import { isValidDeviceReadingtype } from "src/data/devices/logic/deviceLogic"
import { UserRoles } from "src/data/user/user"
import { useFlags } from "src/hooks/useFlags"
import { useInit } from "src/hooks/useInit"
import { useScrollToTopOnRouteChange } from "src/hooks/useScrollToTopOnRouteChange"
import { useTrackPage } from "src/hooks/useTrackPage"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { lazily } from "src/vendor/lazily"

const { CallAssistActivate } = lazily(
  () => import("src/components/CallAssistActivate/CallAssistActivate")
)
const { CallAssistFeedback } = lazily(
  () => import("src/components/CallAssistFeedback/CallAssistFeedback")
)
const { ImageBackdropDemo } = lazily(
  () => import("src/components/Sandbox/ImageBackdropDemo")
)

const { JoinOrganizationWizard } = lazily(
  () =>
    import("src/components/Onboarding/JoinOrganization/JoinOrganizationWizard")
)
const { Onboarding } = lazily(
  () => import("src/components/Onboarding/Onboarding")
)
const { MountingPlateStore } = lazily(
  () => import("src/components/order/MountingPlateStore/MountingPlateStore")
)
const { VisxWorkshop } = lazily(
  () => import("src/components/VisxWorkshop/visxWorkshop")
)
const { Login } = lazily(() => import("src/components/Login/Login"))

const { Logout } = lazily(() => import("src/components/Logout/Logout"))

const { Account } = lazily(() => import("src/components/Account/Account"))
const { ChangePlan } = lazily(
  () => import("src/components/Account/BillingPortal/ChangePlan/ChangePlan")
)
const { ChangePlanSuccess } = lazily(
  () =>
    import("src/components/Account/BillingPortal/ChangePlan/ChangePlanSuccess")
)
const { LayoutMain } = lazily(
  () => import("src/components/AppLayout/LayoutMain")
)
const { ExchangeCodeForToken } = lazily(
  () => import("src/components/Auth/ExchangeCodeForToken")
)
const { Dashboard } = lazily(() => import("src/components/Dashboard/Dashboard"))
const { DeviceList } = lazily(
  () => import("src/components/Devices/DeviceList/DeviceList")
)
const { EventsView } = lazily(() => import("src/components/Events/EventsView"))
const { FlagManager } = lazily(
  () => import("src/components/Flags/FlagsManager")
)
const { GuestAccess } = lazily(
  () => import("src/components/GuestAccess/GuestAccess")
)
const { HomeGroupAddExistingMembersRoute } = lazily(
  () => import("src/components/HomeGroups/HomeGroupAddExistingMembers")
)
const { HomeGroupAddHomesRoute } = lazily(
  () => import("src/components/HomeGroups/HomeGroupAddHomesRoute")
)
const { HomeGroupDetails } = lazily(
  () => import("src/components/HomeGroups/HomeGroupDetails")
)
const { HomeGroups } = lazily(
  () => import("src/components/HomeGroups/HomeGroups")
)
const { HomeGroupsWizard } = lazily(
  () => import("src/components/HomeGroups/HomeGroupsWizard/HomeGroupsWizard")
)
const { AddVirtualDeviceWizard } = lazily(
  () => import("src/components/Homes/AddVirtualDevice/AddVirtualDeviceWizard")
)
const { CreateHomeWizard } = lazily(
  () => import("src/components/Homes/CreateHomeWizard/CreateHomeWizard")
)
const { HomeSetupWizard } = lazily(
  () => import("src/components/Homes/HomeSetupWizard/HomeSetupWizard")
)
const { DeviceDetails } = lazily(
  () => import("src/components/Homes/DeviceDetails/DeviceDetails")
)
const { HomeAddressEdit } = lazily(
  () => import("src/components/Homes/HomeAddress/HomeAddressEdit")
)
const { HomeDetails } = lazily(
  () => import("src/components/Homes/HomeDetails/HomeDetails")
)
const { HomeAddExistingMembersRoute } = lazily(
  () =>
    import(
      "src/components/Homes/HomeDetails/HomeMembers/HomeAddExistingMembers"
    )
)
const { HomeDetailsV2 } = lazily(
  () => import("src/components/Homes/HomeDetailsV2/HomeDetailsV2")
)
const { InstallDevice } = lazily(() => import("src/components/Install/Install"))
const { Integrations } = lazily(
  () => import("src/components/Integrations/Integrations")
)
const { OnboardingCreateOrganizationWizard } = lazily(
  () =>
    import(
      "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OnboardingCreateOrganizationWizard"
    )
)
const { AddMemberAccess } = lazily(
  () => import("src/components/Organizations/Organization/AddMemberAccess")
)
const { Members } = lazily(
  () => import("src/components/Organizations/Organization/Members")
)
const { OrganizationCreateWizard } = lazily(
  () =>
    import(
      "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/OrganizationCreateWizard"
    )
)
const { OrganizationGuestComs } = lazily(
  () => import("src/components/Organizations/OrganizationGuestComs")
)
const { OrganizationsList } = lazily(
  () => import("src/components/Organizations/OrganizationsList")
)
const { RemoteDebugClientUI } = lazily(
  () => import("src/components/RemoteDebugService/RemoteDebugClientUI")
)
const { RemoteDeviceDevTool } = lazily(
  () => import("src/components/RemoteDebugService/RemoteDeviceDevTool")
)
const { AssistServices } = lazily(
  () => import("src/components/ResponseService/AssistServices/AssistServices")
)
const { CallAssistConfigure } = lazily(
  () =>
    import("src/components/ResponseService/AssistServices/CallAssistConfigure")
)
const { ResponseService } = lazily(
  () => import("src/components/ResponseService/ResponseService")
)
const { ResponseServiceWizard } = lazily(
  () =>
    import(
      "src/components/ResponseService/ResponseServiceWizard/ResponseServiceWizard"
    )
)
const { RouteNotFound } = lazily(
  () => import("src/components/RouteNotFound/RouteNotFound")
)
const { Sandbox } = lazily(() => import("src/components/Sandbox/Sandbox"))
const { GeneralSettings } = lazily(
  () => import("src/components/Settings/GeneralSettings")
)
const { AddSettingsProfile } = lazily(
  () => import("src/components/SettingsProfiles/AddSettingsProfile")
)
const { EditSettingsProfile } = lazily(
  () => import("src/components/SettingsProfiles/EditSettingsProfile")
)
const { Signup } = lazily(() => import("src/components/Signup/Signup"))
const { FullscreenView } = lazily(() => import("src/ui/Modals/FullscreenView"))
const { GuestsV2 } = lazily(
  () => import("src/components/Homes/HomeDetailsV2/Guests/GuestsV2")
)
const { ReportsView } = lazily(
  () => import("src/components/Reports/ReportsView")
)

const { ParadiseHomes } = lazily(
  () => import("src/components/Paradise/ParadiseHomes/ParadiseHomes")
)

const { ParadiseHomeDetails } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseHomes/ParadiseHomeDetails/ParadiseHomeDetails"
    )
)

const { ParadiseUsers } = lazily(
  () => import("src/components/Paradise/ParadiseUsers/ParadiseUsers")
)

const { ParadiseUserDetails } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserDetails"
    )
)

const { ParadiseOrganizations } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizations"
    )
)

const { ParadiseOrganizationDetails } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationDetails"
    )
)

const { ParadiseDevices } = lazily(
  () => import("src/components/Paradise/ParadiseDevices/ParadiseDevices")
)

const { ParadiseDeviceDetails } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceDetails"
    )
)

const { ParadiseDeviceReadings } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/ParadiseDeviceReadings"
    )
)

const { ParadiseClients } = lazily(
  () => import("src/components/Paradise/ParadiseClients/ParadiseClients")
)

const { ParadiseClientDetails } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseClients/ParadiseClientDetails/ParadiseClientDetails"
    )
)

const { ParadiseFirmwares } = lazily(
  () => import("src/components/Paradise/ParadiseFirmwares/ParadiseFirmwares")
)

const { ParadiseFirmwareDetails } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseFirmwares/ParadiseFirmwareDetails/ParadiseFirmwareDetails"
    )
)

const { ParadiseProfiles } = lazily(
  () => import("src/components/Paradise/ParadiseProfiles/ParadiseProfiles")
)

const { ParadiseProfileDetails } = lazily(
  () =>
    import(
      "src/components/Paradise/ParadiseProfiles/ParadiseProfileDetails/ParadiseProfileDetails"
    )
)

const REDIRECT_HELP_CROWD_DETECT = "/redirect/crowd_detect"
const REDIRECT_HELP_SHORTCODES = "/redirect/help-shortcodes"

export function AppRouter() {
  const { showNewHomeDetailsView } = useFlags()
  useInit()
  useScrollToTopOnRouteChange()
  useTrackPage()

  return (
    <>
      <Switch>
        <Route
          exact
          path={Routes.GuestAccess.location().pathname}
          component={GuestAccess}
        />

        <Route
          exact
          path={[Routes.CallAssistOperatorFeedback.location().pathname]}
          render={(p) => {
            const searchParams = new URLSearchParams(p.location.search)
            const eventId = searchParams.get("eventId")
            return <CallAssistFeedback eventIdParam={eventId} />
          }}
        />

        <PrivateRoute
          path={Routes.CallAssistActivate.location().pathname}
          render={() => {
            return <CallAssistActivate />
          }}
        ></PrivateRoute>

        <AppRoute exact path={"/sandbox"}>
          <Sandbox />
        </AppRoute>

        <PrivateRoute exact path={"/sandbox/backdrop"}>
          <ImageBackdropDemo />
        </PrivateRoute>

        <AppRoute exact path={"/workshop"}>
          <VisxWorkshop />
        </AppRoute>
        <Route exact path="/">
          <Redirect
            to={{
              pathname: Routes.Dashboard.location().pathname,
              search: window.location.search,
              hash: window.location.hash,
            }}
          />
        </Route>

        <DeepRedirect
          from={HREF_MINUT_BILLING}
          to={Routes.AccountBilling.location().pathname}
        />

        <DeepRedirect
          from={REDIRECT_HELP_CROWD_DETECT}
          to={HREF_MINUT_HELP_CROWD_DETECT}
          external
        />

        <DeepRedirect
          from={REDIRECT_HELP_SHORTCODES}
          to={HREF_MINUT_HELP_SHORTCODES}
          external
        />

        <Route exact path={Routes.Login.location().pathname}>
          <Login />
        </Route>

        <Route
          exact
          path={Routes.Logout.location({ reason: undefined }).pathname}
          component={Logout}
        />

        <UnauthenticatedRoute exact path={Routes.Signup.location().pathname}>
          <Signup />
        </UnauthenticatedRoute>

        <Route
          exact
          path={Routes.OAuthExchangeCodeForToken.location().pathname}
        >
          <ExchangeCodeForToken />
        </Route>

        <PrivateRoute path={Routes.Dashboard.location().pathname}>
          <LayoutMain>
            <Dashboard />
          </LayoutMain>
        </PrivateRoute>

        <DeepRedirect
          from="/properties"
          to={Routes.Homes.location().pathname}
        />

        <PrivateRoute exact path={[Routes.CreateHome.location().pathname]}>
          <CreateHomeWizard />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={[Routes.HomeSetup.location(":homeId").pathname]}
          component={pathFragmentRender(":homeId", (homeId) => (
            <HomeSetupWizard homeId={homeId} />
          ))}
        ></PrivateRoute>

        <AppRoute exact path={Routes.Homes.location().pathname}>
          <Homes />
        </AppRoute>

        <AppRoute exact path={Routes.Home.location(":homeId").pathname}>
          {showNewHomeDetailsView ? <HomeDetailsV2 /> : <HomeDetails />}
        </AppRoute>

        <PrivateRoute
          exact
          path={[Routes.HomeGuestsV2.location(":homeId").pathname]}
          component={pathFragmentRender(":homeId", (homeId) => (
            <LayoutMain>
              {showNewHomeDetailsView ? (
                <GuestsV2 homeId={homeId} />
              ) : (
                <HomeDetails />
              )}
            </LayoutMain>
          ))}
        />

        <PrivateRoute path={["/events"]}>
          <LayoutMain>
            <EventsView />
          </LayoutMain>
        </PrivateRoute>

        <AppRoute exact path={Routes.Sensors.location().pathname}>
          <DeviceList />
        </AppRoute>

        <AppRoute
          exact
          path={Routes.SensorsWithDeviceId.location(":deviceId").pathname}
        >
          <DeviceDetails />
        </AppRoute>

        <PrivateRoute path={Routes.Account.location().pathname}>
          <LayoutMain>
            <Account />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute path={Routes.ChangePlanSuccess.location().pathname}>
          <ChangePlanSuccess />
        </PrivateRoute>

        <AppRoute path={[Routes.OrgIntegrations.location().pathname]}>
          <Integrations />
        </AppRoute>

        <PrivateRoute exact path={[Routes.AssistServices.location().pathname]}>
          <LayoutMain>
            <AssistServices />
          </LayoutMain>
        </PrivateRoute>

        <AppRoute exact path={[Routes.CallAssist.location().pathname]}>
          <CallAssistConfigure />
        </AppRoute>

        <PrivateRoute exact path={[Routes.ResponseService.location().pathname]}>
          <LayoutMain>
            <ResponseService />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute
          exact
          path={[
            Routes.ActivateResponseService.location({ provider: ":provider" })
              .pathname,
            Routes.ActivateResponseService.location({}).pathname,
          ]}
          component={pathFragmentRender(":provider", (provider) => (
            <ResponseServiceWizard provider={provider} />
          ))}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path={[Routes.EditHomeAddress.location(":homeId").pathname]}
        >
          <HomeAddressEdit />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={[Routes.AddVirtualDevice.location(":homeId").pathname]}
          render={(p) => {
            const homeId = p.match.params["homeId"]
            if (!homeId) {
              return <div>ERROR: `homeId` required</div>
            }
            return <AddVirtualDeviceWizard homeId={homeId} />
          }}
        ></PrivateRoute>

        <PrivateRoute exact path={[Routes.ChangePlan.location().pathname]}>
          <ChangePlan />
        </PrivateRoute>

        {/* START Home Profiles routes */}
        <PrivateRoute
          exact
          path={Routes.MonitoringSettingAdd.location().pathname}
        >
          <AddSettingsProfile />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={Routes.MonitoringSettingEdit.location(":profileId").pathname}
        >
          <LayoutMain>
            <EditSettingsProfile />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute
          exact
          path={Routes.ApiClientsEdit.location(":editClientId").pathname}
        >
          <LayoutMain>
            <GeneralSettings />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute path={[Routes.SettingsPresetsNoise.location().pathname]}>
          <LayoutMain>
            <NoiseMonitoringPresets />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute
          path={[Routes.SettingsPresetsIndoorClimate.location().pathname]}
        >
          <LayoutMain>
            <RouteNotFound />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute
          path={[
            Routes.Settings.location().pathname,
            Routes.MonitoringSettings.location().pathname,
            Routes.OrganizationSettings.location().pathname,
            Routes.ApiClientsSetting.location().pathname,
          ]}
        >
          <LayoutMain>
            <GeneralSettings />
          </LayoutMain>
        </PrivateRoute>

        {/* END Home profiles routes */}

        <PrivateRoute path="/install">
          <LayoutMain>
            <InstallDevice />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute path={[Routes.RemoteDebugUser.location().pathname]}>
          <FullscreenView>
            <RemoteDebugClientUI />
          </FullscreenView>
        </PrivateRoute>

        <PrivateRoute path={[Routes.RemoteDebugDev.location().pathname]}>
          <LayoutMain>
            <RemoteDeviceDevTool />
          </LayoutMain>
        </PrivateRoute>

        <Route path="/flags">
          <FlagManager />
        </Route>

        <PrivateRoute path={[Routes.Organizations.location().pathname]}>
          <LayoutMain>
            <OrganizationsList />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute
          exact
          path={[
            Routes.Onboarding.location().pathname,
            Routes.OnboardingCreateOrganization.location().pathname,
            Routes.OnboardingJoinOrganization.location().pathname,
          ]}
          skipMiddleware={{
            Invitation: "Onboarding in progress, prevent loop",
            Onboarding: "Onboarding in progress, prevent loop",
            InjectOrgId: "Onboarding in progress, org id doesn't exist yet",
            AppDataLoaded:
              "Onboarding in progress, prevent wizard from losing state",
            Dunning: "Onboarding in progress, org id doesn't exist yet",
          }}
          render={(p) => {
            switch (p.match.path) {
              case Routes.Onboarding.location().pathname:
                return <Onboarding />
              case Routes.OnboardingCreateOrganization.location().pathname:
                return <OnboardingCreateOrganizationWizard />
              case Routes.OnboardingJoinOrganization.location().pathname:
                return <JoinOrganizationWizard />
            }
          }}
        />

        <PrivateRoute exact path={[Routes.OrgCreate.location().pathname]}>
          <OrganizationCreateWizard />
        </PrivateRoute>

        <PrivateRoute exact path={[Routes.Organization.location().pathname]}>
          <LayoutMain>
            <Members />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute
          exact
          path={[Routes.OrgMemberAdd.location(":orgId").pathname]}
        >
          <AddMemberAccess initialAccessTarget={"org-level-access"} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={[
            Routes.OrgHomeNewMemberAdd.location({
              orgId: ":orgId",
              homeId: ":homeId",
            }).pathname,
          ]}
          component={pathFragmentRender(":homeId", (homeId) => (
            <AddMemberAccess
              homeId={homeId}
              initialAccessTarget={"home-level-access"}
            />
          ))}
        />

        <PrivateRoute
          exact
          path={[
            Routes.OrgHomeExistingMemberAdd.location({
              orgId: ":orgId",
              homeId: ":homeId",
            }).pathname,
          ]}
        >
          <HomeAddExistingMembersRoute />
        </PrivateRoute>

        <PrivateRoute path={[Routes.OldHomeGroups.location().pathname]}>
          <Redirect to={Routes.HomeGroups.location()} />
        </PrivateRoute>

        <PrivateRoute exact path={[Routes.HomeGroups.location().pathname]}>
          <LayoutMain>
            <HomeGroups />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute
          exact
          path={[Routes.HomeGroupAddMember.location(":homeGroupId").pathname]}
        >
          <HomeGroupAddExistingMembersRoute />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={[Routes.HomeGroupDetails.location(":homeGroupId").pathname]}
          component={pathFragmentRender("homeGroupId", (homeGroupId) => {
            return (
              <LayoutMain>
                <HomeGroupDetails homeGroupId={homeGroupId} />
              </LayoutMain>
            )
          })}
        />
        <PrivateRoute
          exact
          path={[
            Routes.HomeGroupAddMemberEmail.location(":homeGroupId").pathname,
          ]}
          component={pathFragmentRender(":homeGroupId", (homeGroupId) => (
            <AddMemberAccess
              homeGroupId={homeGroupId}
              initialAccessTarget={"homegroup-level-access"}
            />
          ))}
        />
        <PrivateRoute exact path={[Routes.CreateHomeGroup.location().pathname]}>
          <HomeGroupsWizard />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={[Routes.HomeGroupAddHomes.location(":homeGroupId").pathname]}
        >
          <HomeGroupAddHomesRoute />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={[
            Routes.OrgGuestCom.location().pathname,
            Routes.OrgGuestComCreateRule.location(":ruleTemplateId").pathname,
            Routes.OrgGuestComEditRule.location(":ruleId").pathname,
          ]}
        >
          <LayoutMain>
            <OrganizationGuestComs />
          </LayoutMain>
        </PrivateRoute>

        <PrivateRoute exact path={[Routes.PlateStore.location().pathname]}>
          <MountingPlateStore />
        </PrivateRoute>

        <PrivateRoute exact path={[Routes.Reports.location().pathname]}>
          <LayoutMain>
            <ReportsView />
          </LayoutMain>
        </PrivateRoute>

        <ParadiseRoute
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
          path={Routes.ParadiseHome.location(":homeId").pathname}
          render={(p) => {
            const homeId = p.match.params["homeId"]
            if (!homeId) {
              return <div>ERROR: `homeId` required</div>
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseHomeDetails homeId={homeId} />
              </LayoutMain>
            )
          }}
        />

        <ParadiseRoute
          path={Routes.ParadiseHomes.location().pathname}
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
        >
          <LayoutMain noPadding noMaxWidth>
            <ParadiseHomes />
          </LayoutMain>
        </ParadiseRoute>

        <ParadiseRoute
          path={Routes.ParadiseUser.location(":userId").pathname}
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
          render={(p) => {
            const userId = p.match.params["userId"]
            if (!userId) {
              return <div>ERROR: `userId` required</div>
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseUserDetails userId={userId} />
              </LayoutMain>
            )
          }}
        />

        <ParadiseRoute
          path={Routes.ParadiseUsers.location().pathname}
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
        >
          <LayoutMain noPadding noMaxWidth>
            <ParadiseUsers />
          </LayoutMain>
        </ParadiseRoute>

        <ParadiseRoute
          path={Routes.ParadiseOrganization.location(":orgId").pathname}
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
          render={(p) => {
            const orgId = p.match.params["orgId"]
            if (!orgId) {
              return <div>ERROR: `orgId` required</div>
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseOrganizationDetails orgId={orgId} />
              </LayoutMain>
            )
          }}
        />

        <ParadiseRoute
          path={Routes.ParadiseOrganizations.location().pathname}
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
        >
          <LayoutMain noPadding noMaxWidth>
            <ParadiseOrganizations />
          </LayoutMain>
        </ParadiseRoute>

        <ParadiseRoute
          path={
            Routes.ParadiseDeviceReadings.location({
              deviceId: ":deviceId",
              type: ":type",
            }).pathname
          }
          render={(p) => {
            const deviceId = p.match.params["deviceId"]
            const type = p.match.params["type"]
            if (!deviceId || !type) {
              return <div>ERROR: `deviceId` and `type` required</div>
            }

            if (!isValidDeviceReadingtype(type)) {
              return (
                <LayoutMain>
                  <EmptyState
                    title="Could not find reading type"
                    icon={<Svg404 />}
                  />
                </LayoutMain>
              )
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseDeviceReadings deviceId={deviceId} type={type} />
              </LayoutMain>
            )
          }}
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
        />

        <ParadiseRoute
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
          path={Routes.ParadiseDevice.location(":deviceId").pathname}
          render={(p) => {
            const deviceId = p.match.params["deviceId"]
            if (!deviceId) {
              return <div>ERROR: `deviceId` required</div>
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseDeviceDetails deviceId={deviceId} />
              </LayoutMain>
            )
          }}
        />

        <ParadiseRoute
          path={Routes.ParadiseDevices.location().pathname}
          allowedRoles={[UserRoles.SALES_ADMIN, UserRoles.SUPERADMIN]}
        >
          <LayoutMain noPadding noMaxWidth>
            <ParadiseDevices />
          </LayoutMain>
        </ParadiseRoute>

        <ParadiseRoute
          path={Routes.ParadiseClient.location(":clientId").pathname}
          render={(p) => {
            const clientId = p.match.params["clientId"]
            if (!clientId) {
              return <div>ERROR: `clientId` required</div>
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseClientDetails clientId={clientId} />
              </LayoutMain>
            )
          }}
        />

        <ParadiseRoute path={Routes.ParadiseClients.location().pathname}>
          <LayoutMain noPadding noMaxWidth>
            <ParadiseClients />
          </LayoutMain>
        </ParadiseRoute>

        <ParadiseRoute
          path={Routes.ParadiseFirmware.location(":fwNumber").pathname}
          render={(p) => {
            const fwNumber = p.match.params["fwNumber"]
            if (!fwNumber) {
              return <div>ERROR: `fwNumber` required</div>
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseFirmwareDetails fwNumber={fwNumber} />
              </LayoutMain>
            )
          }}
        />

        <ParadiseRoute path={Routes.ParadiseFirmwares.location().pathname}>
          <LayoutMain noPadding noMaxWidth>
            <ParadiseFirmwares />
          </LayoutMain>
        </ParadiseRoute>

        <ParadiseRoute
          path={Routes.ParadiseProfile.location(":profileId").pathname}
          render={(p) => {
            const profileId = p.match.params["profileId"]
            if (!profileId) {
              return <div>ERROR: `profileId` required</div>
            }

            return (
              <LayoutMain noPadding noMaxWidth>
                <ParadiseProfileDetails profileId={profileId} />
              </LayoutMain>
            )
          }}
        />

        <ParadiseRoute path={Routes.ParadiseProfiles.location().pathname}>
          <LayoutMain noPadding noMaxWidth>
            <ParadiseProfiles />
          </LayoutMain>
        </ParadiseRoute>

        <ParadiseRoute path={Routes.ParadiseDashboard.location().pathname}>
          <Redirect to={Routes.ParadiseUsers.location()} />
        </ParadiseRoute>

        <Route component={RouteNotFound} />
      </Switch>
    </>
  )
}

// N.B: There is currently a bug with AppRoute and ErrorBoundary: the contents
// won't re-render when switching to another route. Hopefully this is something
// that will be resolved once we replace PrivateRoute with AuthenticatedRoute
// from the OAuth PR.
function AppRoute({ children, ...props }: RouteProps) {
  return (
    <PrivateRoute {...props}>
      <LayoutMain>{children}</LayoutMain>
    </PrivateRoute>
  )
}

/** Utility function to inject a variable name in a Route's `component` callback.
 *
 * Make sure that the `fragmentName` parameter matches the fragment you give to
 * the `path` prop of the Route.
 *
 * @deprecated This can be handled by react-router builtins `render` and `component`
 */
function pathFragmentRender(
  fragmentName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (fragmentVar: any) => ReactElement<any, any>
) {
  const fragmentVarName = fragmentName.replace(":", "")
  return function _routeRender(renderProps: {
    match: { params: { [key: string]: string } }
  }) {
    return render(renderProps?.match?.params?.[fragmentVarName])
  }
}
