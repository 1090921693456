import { Fragment } from "react"
import styled from "styled-components"

import {
  TFetchNoiseMonitoringPresetsResponse,
  useDeleteNoiseMonitoringPresets,
  usePostNoiseMonitoringPresets,
} from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"

type TPreset = TFetchNoiseMonitoringPresetsResponse["profiles"][number]
type NoiseMonitoringPresetTableProps = {
  presets: TFetchNoiseMonitoringPresetsResponse["profiles"] | undefined
}

export function NoiseMonitoringPresetTable({
  presets,
}: NoiseMonitoringPresetTableProps) {
  const { tPlaceholder } = useTranslate()

  const postNoiseMonitoringPresets = usePostNoiseMonitoringPresets()
  const deleteNoiseMonitoringPresets = useDeleteNoiseMonitoringPresets()

  function handleEdit() {
    alert("implement me")
    // TODO MON-737: Redirect to preset edit page when once that view is available
  }
  function handleDuplicate(data: TPreset) {
    postNoiseMonitoringPresets.mutate(data)
  }
  function handleDelete(id: string) {
    deleteNoiseMonitoringPresets.mutate(id)
  }

  const rows =
    presets?.map((preset) => {
      return (
        <Fragment key={preset.id}>
          <div>{preset.name}</div>
          <div>{tPlaceholder(`${preset.number_of_homes} rental units`)}</div>
          <div>
            <PresetOptions
              preset={preset}
              onEdit={handleEdit}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
            />
          </div>
        </Fragment>
      )
    }) ?? []

  const mobileRows =
    presets?.map((preset) => {
      return (
        <MobileCard
          key={preset.id}
          preset={preset}
          callbacks={{
            onEdit: handleEdit,
            onDuplicate: handleDuplicate,
            onDelete: handleDelete,
          }}
        />
      )
    }) ?? []

  return (
    <div>
      <GridTable
        templateColumns="1fr 3fr auto"
        header={[
          <div key="1">{tPlaceholder("Preset name")}</div>,
          <div key="2">{tPlaceholder("Applies to")}</div>,
          <div key="3"></div>,
        ]}
        rows={rows}
        mobileRows={mobileRows}
      />
    </div>
  )
}

type TPResetOptionsCallbacks = {
  onEdit: () => void
  onDuplicate: (d: TPreset) => void
  onDelete: (id: string) => void
}

type PresetOptionsProps = TPResetOptionsCallbacks & {
  preset: TPreset
}

function PresetOptions({
  preset,
  onDuplicate,
  onEdit,
  onDelete,
}: PresetOptionsProps) {
  const { t, langKeys } = useTranslate()

  return (
    <MoreButton
      menuItems={[
        {
          contents: t(langKeys.edit),
          key: "edit",
          onClick: onEdit,
        },
        {
          contents: t(langKeys.duplicate),
          key: "duplicate",
          onClick: () => onDuplicate(preset),
        },
        {
          contents: t(langKeys.delete),
          key: "delete",
          onClick: () => onDelete(preset.id),
        },
      ]}
    />
  )
}

function MobileCard({
  preset,
  callbacks,
}: {
  preset: TPreset
  callbacks: TPResetOptionsCallbacks
}) {
  const { tPlaceholder } = useTranslate()

  return (
    <MCard border boxShadow={false}>
      <CardContents>
        <div>
          <MText variant="heading3">{preset.name}</MText>
          <div>{tPlaceholder(`${preset.number_of_homes} rental units`)}</div>
        </div>

        <PresetOptions preset={preset} {...callbacks} />
      </CardContents>
    </MCard>
  )
}

const CardContents = styled.div`
  display: flex;

  & :first-child {
    flex-basis: 100%;
  }
`
