import styled from "styled-components"

import { Breadcrumbs, IBreadcrumb } from "src/ui/Breadcrumbs/Breadcrumbs"
import { MainView } from "src/ui/Layout/MainView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function BreadcrumbView({
  breadcrumbs,
  title,
  description,
  actionBar,
  children,
}: {
  breadcrumbs: IBreadcrumb[]
  title: string
  description: string
  actionBar: React.ReactNode
  children: React.ReactNode
}) {
  return (
    <BreadCrumbBox>
      <Breadcrumbs items={breadcrumbs} />

      <MainView
        title={
          <div>
            <MText variant="heading2">{title}</MText>
            <MText color="secondary">{description}</MText>
          </div>
        }
        titleBarProps={{ actionBar }}
      >
        {children}
      </MainView>
    </BreadCrumbBox>
  )
}

const BreadCrumbBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
`
