import { Tooltip } from "@material-ui/core"

import { HardwareType, TMountStatus } from "src/data/devices/types/deviceTypes"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import P2SensorMounted from "src/ui/icons/p2-mounted.svg"
import P2SensorUnknown from "src/ui/icons/p2-unknown-mount-state.svg"
import P2SensorUnmounted from "src/ui/icons/p2-unmounted.svg"
import P3SensorMounted from "src/ui/icons/p3-mounted.svg"
import P3SensorUnknown from "src/ui/icons/p3-unknown-mount-state.svg"
import P3SensorUnmounted from "src/ui/icons/p3-unmounted.svg"

export function MountStatus({
  deviceType,
  offline,
  status,
  ...props
}: {
  deviceType: HardwareType
  offline: boolean
  status?: TMountStatus
} & React.SVGProps<SVGSVGElement>) {
  const { t } = useTranslate()
  const content = getMountStatusContent({ offline, t, deviceType, status })

  return (
    <Tooltip title={<span>{content.tooltip}</span>}>
      <div>
        <content.icon
          width={24}
          height={24}
          color={content.colorOverride}
          {...props}
        />
      </div>
    </Tooltip>
  )
}

function getMountStatusContent({
  offline,
  t,
  deviceType,
  status,
}: {
  offline: boolean
  t: ITranslateFunction
  deviceType: HardwareType
  status?: TMountStatus
}) {
  const isP3 = deviceType === HardwareType.P3
  const aggregatedStatus =
    status === "mounted" && offline ? "offline-mounted" : status

  switch (aggregatedStatus) {
    case "offline-mounted": {
      return {
        icon: isP3 ? P3SensorMounted : P2SensorMounted,
        tooltip: t(langKeys.tamper_unknown),
        colorOverride: mColors.neutralDark2,
      }
    }

    case "mounted": {
      return {
        icon: isP3 ? P3SensorMounted : P2SensorMounted,
        tooltip: t(langKeys.tamper_attached),
        colorOverride: !offline ? mColors.systemGood : undefined,
      }
    }

    case "unmounted": {
      return {
        icon: isP3 ? P3SensorUnmounted : P2SensorUnmounted,
        tooltip: t(langKeys.tamper_removed),
      }
    }

    case "unsupported": {
      return {
        icon: isP3 ? P3SensorUnknown : P2SensorUnknown,
        tooltip: t(langKeys.tamper_unsupported),
      }
    }

    default: {
      return {
        icon: isP3 ? P3SensorUnknown : P2SensorUnknown,
        tooltip: t(langKeys.tamper_unknown),
      }
    }
  }
}
