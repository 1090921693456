import { CSSProperties } from "react"
import styled from "styled-components"

import { mColors } from "src/ui/colors"

export type MSkeletonVariant = "text" | "rect" | "circle"

type MSkeletonProps = {
  variant?: MSkeletonVariant
  width?: string | number
  height?: string | number
  inline?: boolean
  style?: CSSProperties
}

export function MSkeleton({
  variant = "text",
  width,
  height,
  inline,
  style,
}: MSkeletonProps) {
  const size = getSize({
    variant,
    width,
    height,
  })

  return (
    <StyledSkeleton
      variant={variant}
      width={size.width}
      height={size.height}
      inline={inline}
      style={style}
    />
  )
}

function getSize({
  variant,
  width,
  height,
}: {
  variant: MSkeletonVariant
  width?: string | number
  height?: string | number
}) {
  switch (variant) {
    case "circle":
      return {
        width: width || 40,
        height: height || 40,
      }
    case "rect":
    default:
      return {
        width: width || "100%",
        height: height || 30,
      }
  }
}

const StyledSkeleton = styled.div<MSkeletonProps>`
  display: ${({ inline }) => (inline ? "inline-block" : "block")};
  width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
  height: ${({ height }) =>
    typeof height === "number" ? `${height}px` : height};
  border-radius: ${({ variant }) => (variant === "circle" ? "9999px" : "4px")};
  ${({ variant }) => variant === "text" && "transform: scale(1, 0.6)"};
  background: ${mColors.neutral};

  animation: fadeOut 1s alternate ease-in-out 0.5s infinite;

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0.4;
    }
  }
`
