import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import RentalUnitsEmptyStateImage from "./images/rental-units-empty-state.svg"

export function NoHomes() {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <ImageBox>
        <RentalUnitsEmptyStateImage />
      </ImageBox>
      <ContentWrapper>
        <MText variant="heading2" textAlign="center" marginBottom={spacing.XS}>
          {t(langKeys.rental_units_empty_state_title)}
        </MText>
        <MText variant="bodyS" textAlign="center" marginBottom={spacing.L}>
          {t(langKeys.rental_units_empty_state_description)}
        </MText>
        <CenterWrapper>
          <MButton appHref={Routes.CreateHome.location()}>
            {t(langKeys.create_home)}
          </MButton>
        </CenterWrapper>
      </ContentWrapper>
    </div>
  )
}

const ContentWrapper = styled.div`
  margin-top: -1rem;
  margin-inline: auto;
  max-width: 600px;
`

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ImageBox = styled.div`
  max-width: 800px;
  margin: 0 auto;

  > svg {
    width: 100%;
    height: 100%;
  }
`
