import { ButtonHTMLAttributes, forwardRef, MouseEventHandler } from "react"
import styled, { css } from "styled-components"

import CircularProgress from "@material-ui/core/CircularProgress"

import { MLocation } from "src/router/routeTypes"
import { useRouter } from "src/router/useRouter"
import { colorScale, mColors } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import { spacing } from "src/ui/spacing"

export type TMButtonProps = {
  variant?: TButtonVariant
  color?: TButtonColor
  size?: TButtonSize
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
  style?: React.CSSProperties
  className?: string
  appHref?: string | (Partial<MLocation> & { pathname: string })
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  children?: React.ReactNode
  shape?: TButtonShape
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

function _MButton(
  {
    variant = "primary",
    color = "default",
    size = "medium",
    disabled = false,
    loading = false,
    fullWidth = false,
    style,
    appHref,
    className,
    onClick,
    type = "button",
    children,
    shape = "default",
    startIcon,
    endIcon,
    ...props
  }: TMButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { navigate } = useRouter()

  function navigateToAppHref() {
    appHref && navigate(appHref)
  }

  const _disabled = disabled || loading

  return (
    <Button
      ref={ref}
      disabled={_disabled}
      onClick={appHref ? navigateToAppHref : onClick}
      $fullWidth={fullWidth}
      $variant={variant}
      $size={size}
      $color={color}
      $shape={shape}
      style={style}
      className={className}
      type={type}
      {...props}
    >
      <Box>
        {loading && (
          <StyledCircularProgress
            size="1rem"
            $color={color}
            $variant={variant}
            $disabled={_disabled}
          />
        )}
        {startIcon}
        {children}
        {endIcon}
      </Box>
    </Button>
  )
}

export const MButton = forwardRef(_MButton)

type TButtonVariant = "primary" | "secondary" | "subtle" | "minimal"
type TButtonColor = "default" | "destructive" | "contrast"
type TButtonSize = "small" | "medium"
type TButtonShape = "default" | "circle"

function getPadding({
  $variant,
  $size,
  $shape,
}: {
  $variant: TButtonVariant
  $size: TButtonSize
  $shape: TButtonShape
}) {
  if ($variant === "minimal") {
    return "0"
  }

  switch ($size) {
    case "small":
      return `${spacing.XS} ${$shape === "default" ? spacing.M : spacing.XS}`
    default:
      return `${spacing.S} ${$shape === "default" ? spacing.L : spacing.S}`
  }
}

function getFontSize({ $size }: { $size: TButtonSize }) {
  switch ($size) {
    case "small":
      return "0.875rem"
    case "medium":
    default: {
      return "1rem"
    }
  }
}

function getTextdecoration({ $variant }: { $variant: TButtonVariant }) {
  switch ($variant) {
    case "minimal":
      return "underline"
    default: {
      return "unset"
    }
  }
}

// Reset default browser styling
export const ButtonStyleReset = css`
  background-image: none;
  font: inherit;
  font-weight: ${fontWeight.semiBold};
  text-transform: none;
  margin: 0;
  border-radius: 9999px;
  cursor: pointer;
  outline: inherit;
  transition: all 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: visible;
  appearance: none;
  line-height: 1.4;
`

const Button = styled.button<{
  $variant: TButtonVariant
  $size: TButtonSize
  $color: TButtonColor
  $shape: TButtonShape
  $fullWidth: boolean
}>`
  ${ButtonStyleReset}

  background: ${({ $variant, $color }) =>
    buttonColors[$color][$variant].background};
  border: ${({ $variant, $color }) => buttonColors[$color][$variant].border};
  color: ${({ $variant, $color }) => buttonColors[$color][$variant].color};
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "unset")};

  font-size: ${({ $size }) => getFontSize({ $size })};
  padding: ${({ $variant, $size, $shape }) =>
    getPadding({ $variant, $size, $shape })};
  text-decoration: ${({ $variant }) => getTextdecoration({ $variant })};

  &:disabled {
    background: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].backgroundDisabled};
    border: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].borderDisabled};
    color: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].colorDisabled};
    pointer-events: none;
  }

  &:focus-visible {
    outline-color: ${colorScale.gaff[600]};
    outline-offset: 2px;
    outline-width: 1px;
    outline-style: dashed;
  }

  &:hover {
    background: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].backgroundHover};
    border: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].borderHover};
    color: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].colorHover};
  }

  &:active {
    background: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].backgroundActive};
    border: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].borderActive};
    color: ${({ $variant, $color }) =>
      buttonColors[$color][$variant].colorActive};
  }
`

export const StyledCircularProgress = styled(CircularProgress)<{
  $variant: TButtonVariant
  $color: TButtonColor
  $disabled: boolean
}>`
  color: ${({ $variant, $color, $disabled }) =>
    $disabled
      ? buttonColors[$color][$variant].colorDisabled
      : buttonColors[$color][$variant].color};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.XS};
`

type TButtonColors = {
  [Key in TButtonColor]: {
    [Key in TButtonVariant]: {
      color: string
      colorHover: string
      colorActive: string
      colorDisabled: string
      background: string
      backgroundHover: string
      backgroundActive: string
      backgroundDisabled: string
      border: string
      borderHover: string
      borderActive: string
      borderDisabled: string
    }
  }
}

const buttonColors: TButtonColors = {
  default: {
    primary: {
      color: mColors.textContrast,
      colorActive: mColors.textContrast,
      colorHover: mColors.textContrast,
      colorDisabled: colorScale.gaff[300],
      background: mColors.primary,
      backgroundHover: mColors.primary,
      backgroundActive: colorScale.gaff[600],
      backgroundDisabled: colorScale.gaff[100],
      border: `1px solid  ${mColors.primary}`,
      borderHover: `1px solid  ${colorScale.gaff[600]}`,
      borderActive: `1px solid  ${colorScale.gaff[600]}`,
      borderDisabled: `1px solid  ${colorScale.gaff[100]}`,
    },
    secondary: {
      color: mColors.primary,
      colorHover: mColors.primary,
      colorActive: mColors.primary,
      colorDisabled: colorScale.gaff[300],
      background: mColors.primaryLight,
      backgroundHover: mColors.primaryLight,
      backgroundActive: colorScale.gaff[200],
      backgroundDisabled: colorScale.gaff[100],
      border: `1px solid  ${mColors.primaryLight}`,
      borderHover: `1px solid  ${colorScale.gaff[200]}`,
      borderActive: `1px solid  ${colorScale.gaff[200]}`,
      borderDisabled: `1px solid  ${colorScale.gaff[100]}`,
    },
    subtle: {
      color: mColors.primary,
      colorHover: mColors.primary,
      colorActive: mColors.primary,
      colorDisabled: colorScale.gaff[300],
      background: "none",
      backgroundHover: "none",
      backgroundActive: colorScale.gaff[100],
      backgroundDisabled: "none",
      border: `1px solid  ${colorScale.gaff[200]}`,
      borderHover: `1px solid  ${colorScale.gaff[300]}`,
      borderActive: `1px solid  ${colorScale.gaff[300]}`,
      borderDisabled: `1px solid  ${colorScale.gaff[200]}`,
    },
    minimal: {
      color: mColors.primary,
      colorHover: colorScale.gaff[700],
      colorActive: colorScale.gaff[700],
      colorDisabled: colorScale.gaff[300],
      background: "none",
      backgroundHover: "none",
      backgroundActive: "none",
      backgroundDisabled: "none",
      border: "none",
      borderHover: "none",
      borderActive: "none",
      borderDisabled: "none",
    },
  },
  contrast: {
    primary: {
      color: mColors.primary,
      colorHover: mColors.primary,
      colorActive: mColors.primary,
      colorDisabled: colorScale.gaff[600],
      background: mColors.neutralLight,
      backgroundHover: mColors.neutralLight,
      backgroundActive: colorScale.gaff[200],
      backgroundDisabled: colorScale.gaff[400],
      border: `1px solid  ${mColors.neutralLight}`,
      borderHover: `1px solid  ${colorScale.gaff[200]}`,
      borderActive: `1px solid  ${colorScale.gaff[200]}`,
      borderDisabled: `1px solid  ${colorScale.gaff[400]}`,
    },
    secondary: {
      color: mColors.primary,
      colorHover: mColors.primary,
      colorActive: mColors.primary,
      colorDisabled: colorScale.gaff[600],
      background: colorScale.gaff[200],
      backgroundHover: colorScale.gaff[200],
      backgroundActive: colorScale.gaff[300],
      backgroundDisabled: colorScale.gaff[400],
      border: `1px solid  ${colorScale.gaff[200]}`,
      borderHover: `1px solid  ${colorScale.gaff[300]}`,
      borderActive: `1px solid  ${colorScale.gaff[300]}`,
      borderDisabled: `1px solid  ${colorScale.gaff[400]}`,
    },
    subtle: {
      color: mColors.textContrast,
      colorHover: mColors.textContrast,
      colorActive: mColors.primary,
      colorDisabled: colorScale.gaff[400],
      background: "none",
      backgroundHover: "none",
      backgroundActive: colorScale.gaff[200],
      backgroundDisabled: "none",
      border: `1px solid  ${colorScale.gaff[200]}`,
      borderHover: `1px solid  ${colorScale.gaff[300]}`,
      borderActive: `1px solid  ${colorScale.gaff[200]}`,
      borderDisabled: `1px solid  ${colorScale.gaff[400]}`,
    },
    minimal: {
      color: mColors.textContrast,
      colorHover: colorScale.gaff[300],
      colorActive: colorScale.gaff[300],
      colorDisabled: colorScale.gaff[400],
      background: "none",
      backgroundHover: "none",
      backgroundActive: "none",
      backgroundDisabled: "none",
      border: "none",
      borderHover: "none",
      borderActive: "none",
      borderDisabled: "none",
    },
  },
  destructive: {
    primary: {
      color: mColors.textContrast,
      colorHover: mColors.textContrast,
      colorActive: mColors.textContrast,
      colorDisabled: colorScale.error[400],
      background: mColors.systemError,
      backgroundHover: mColors.systemError,
      backgroundActive: mColors.systemErrorDark,
      backgroundDisabled: colorScale.error[200],
      border: `1px solid  ${mColors.systemError}`,
      borderHover: `1px solid  ${mColors.systemErrorDark}`,
      borderActive: `1px solid  ${mColors.systemErrorDark}`,
      borderDisabled: `1px solid  ${colorScale.error[200]}`,
    },
    secondary: {
      color: mColors.systemErrorDark,
      colorHover: mColors.systemErrorDark,
      colorActive: mColors.textContrast,
      colorDisabled: colorScale.error[400],
      background: colorScale.error[200],
      backgroundHover: colorScale.error[200],
      backgroundActive: mColors.systemErrorDark,
      backgroundDisabled: colorScale.error[200],
      border: `1px solid  ${colorScale.error[200]}`,
      borderHover: `1px solid  ${mColors.systemErrorDark}`,
      borderActive: `1px solid  ${mColors.systemErrorDark}`,
      borderDisabled: `1px solid  ${colorScale.error[200]}`,
    },
    subtle: {
      color: mColors.systemErrorDark,
      colorHover: mColors.systemErrorDark,
      colorActive: mColors.systemErrorDark,
      colorDisabled: colorScale.error[400],
      background: "none",
      backgroundHover: "none",
      backgroundActive: colorScale.error[200],
      backgroundDisabled: "none",
      border: `1px solid  ${colorScale.error[400]}`,
      borderHover: `1px solid  ${colorScale.error[500]}`,
      borderActive: `1px solid  ${colorScale.error[400]}`,
      borderDisabled: `1px solid  ${colorScale.error[200]}`,
    },
    minimal: {
      color: mColors.systemErrorDark,
      colorHover: colorScale.error[700],
      colorActive: colorScale.error[700],
      colorDisabled: colorScale.error[400],
      background: "none",
      backgroundHover: "none",
      backgroundActive: "none",
      backgroundDisabled: "none",
      border: "none",
      borderHover: "none",
      borderActive: "none",
      borderDisabled: "none",
    },
  },
}
